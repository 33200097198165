import { useRouter } from 'next/router';

import { SitecoreRouteContainer } from '@containers';
import { NextStaticRoute, getStaticPage } from '@dxp-next-server';
import Layout from '@layouts/main';

import { businessUnit, label, name, countryCode, transformFunctions } from '../config';
import components from '../sitecoreComponents';

const NotFoundPage: NextStaticRoute = ({ layoutData, i18n, path }) => {
  const { locale } = useRouter();

  if (locale === 'default') {
    return null;
  }

  return (
    <SitecoreRouteContainer
      businessUnit={businessUnit}
      label={label}
      name={name}
      layoutData={layoutData}
      path={path}
      i18n={i18n}
      components={components}>
      <Layout />
    </SitecoreRouteContainer>
  );
};

export const getStaticProps = getStaticPage(countryCode, 'notfound', transformFunctions);

export default NotFoundPage;
